<template>
    <div>
        <div class="search-wrap">
            <van-search v-model="keyword" placeholder="请输入搜索关键词" @search="onSearch" @cancel="onCancel" />
            <van-dropdown-menu active-color="#1989fa">
                <van-dropdown-item v-model="goodType" :options="option1" />
                <van-dropdown-item v-model="goodSort" :options="option2" />
            </van-dropdown-menu>
        </div>
        <van-list class="good-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="80">
            <van-card v-for="item in resultList" :key="item.tao_id" :price="parseFloat(item.quanhou_jiage).toFixed(2)" :desc="'佣金比率：'+ parseFloat(item.tkrate3).toFixed(2) + '%'" :title="item.title" :thumb="item.pict_url">
                <template #tags>
                    <van-tag plain type="danger" v-show="item.coupon_info">{{ item.coupon_info }}</van-tag>
                </template>
                <template #footer>
                    <van-button size="mini" @click="setCopyLink(item)">生成口令</van-button>
                </template>
            </van-card>
        </van-list>
        <van-dialog v-model="showDialog" :show-confirm-button="false">
            <van-field class="tkl-content" v-model="newTKL" type="textarea" rows="4" placeholder="转换后口令" />
            <span class="copy-text" :data-clipboard-text="newTKL" @click="copyTKL">一键复制</span>
        </van-dialog>
        <Navi />
    </div>
</template>

<script>
import Clipboard from "clipboard";
import Navi from '@/components/Navigator'

export default {
    components: {
        Navi
    },
    data() {
        return {
            // userInfo: null,
            keyword: '',
            resultList: [],
            pageindex: 1,
            page_size: 10,
            newTKL: '',
            loading: false,
            finished: false,
            showDialog: false,
            goodSort:'new',
            goodType:'',
            option1: [
                { text: '全部商品', value: '' },
                { text: '优惠券商品', value: 1 },
            ],
            option2: [
                { text: '综合排序', value: 'new' },
                { text: '佣金比率从高到低', value: 'commission_rate_desc' },
                { text: '总销量从高到低', value: 'total_sale_num_desc' },
                { text: '月销量从高到低', value: 'sale_num_desc' },
                { text: '价格从低到高', value: 'price_asc' },
            ],
            relation_id: '',
        }
    },
    created(){
        // this.userInfo = this.$store.state.user.userInfo;
        this.relation_id = this.$store.state.user.userInfo.relation_id
    },
    watch:{
        goodType(){
            if(this.keyword) this.onSearch()
        },
        goodSort(){
            if(this.keyword) this.onSearch()
        }
    },
    methods: {
        onSearch() {
            this.resultList = []
            this.onLoad()
        },
        onLoad() {
            if (!this.keyword) {
                this.loading = false;
                return false;
            }
            let params = { q: this.keyword, page: this.pageindex, page_size: this.page_size }
            if(this.goodType==1) params.youquan = 1
            if(this.goodSort) params.sort = this.goodSort
            this.$ask.post('/searchGoods', params)
                .then(res => {
                    // 数据全部加载完成
                    if (!res.data || res.data.length == 0) {
                        this.finished = true;
                    }
                    else {
                        this.pageindex++;
                        this.resultList = this.resultList.concat(res.data)
                    }
                    // 加载状态结束
                    this.loading = false;
                })
                .catch(this.$toastError)
        },
        onCancel() {

        },
        setCopyLink(item) {
            let params = { num_iid: item.tao_id }
            if(this.relation_id) params.relation_id = this.relation_id
            this.$ask.post('/convertTKLByID', params)
                .then(res => {
                    const resData = res.data
                    this.newTKL = `${resData.tkl} ${resData.shorturl2} ${resData.title}`
                    this.showDialog = true;
                })
                .catch(this.$toastError)
        },
        copyTKL() {
            let clipboard = new Clipboard(".copy-text");
            let self = this;
            clipboard.on("success", () => {
                self.$toast("复制成功");
                self.showDialog = false;
                clipboard.destroy();
            });
            clipboard.on("error", () => {
                self.$toast("该浏览器不支持自动复制");
                self.showDialog = false;
                clipboard.destroy();
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.search-wrap {
    position: fixed;
    width: 100vw;
    z-index: 2;
}
.good-list {
    padding: 105px 0 55px;
}
.copy-text {
    text-align: center;
    display: block;
    line-height: 28px;
    padding: 10px;
    color: #4fc08d;
}
</style>